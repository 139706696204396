<template>
    <div class="padding-container">
        <div class="content-load" v-if="detail_loading"></div>
        <div id="loader" v-if="detail_loading"></div>
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="row mt-3">
            <div class="col-lg-6 my-2">
                <div class="row flex-column">
                    <div class="col-lg-12 my-2">
                        <div class="box-manifest h-100">
                            <h5 class="mb-0 fw-bold">Informasi Pengirim</h5>
                            <table
                                class="w-100 ps-0 fw-bold mt-3"
                                cellpadding="10"
                            >
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">
                                        Nama Pengirim
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail.information_sender.name
                                                ? dataDetail.information_sender
                                                      .name
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">
                                        No. Telp Pengirim
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        +{{
                                            dataDetail.information_sender.phone
                                                ? dataDetail.information_sender
                                                      .phone
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 w-150 fw-medium">
                                        Alamat Pengirim
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail.information_sender
                                                .address
                                                ? dataDetail.information_sender
                                                      .address
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-12 my-2">
                        <div class="box-manifest h-100">
                            <h5 class="mb-0 fw-bold">Informasi Pesanan</h5>
                            <table
                                class="w-100 ps-0 fw-bold mt-3"
                                cellpadding="10"
                            >
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">
                                        Tanggal Dibuat
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail.information_order
                                                .created_at
                                                ? dataDetail.information_order
                                                      .created_at
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr
                                    class="border-bottom"
                                    v-if="dataDetail.information_order.lead_id"
                                >
                                    <td class="ps-0 w-150 fw-medium">
                                        Deal ID
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <router-link
                                            class="text-primary"
                                            :to="
                                                `/data/leads/detail?lead_id=` +
                                                dataDetail.information_order
                                                    .lead_id
                                            "
                                        >
                                            <u>
                                                {{
                                                    dataDetail.information_order
                                                        .deal_id
                                                        ? dataDetail
                                                              .information_order
                                                              .deal_id
                                                        : "-"
                                                }}
                                                <i
                                                    class="pi pi-arrow-up-right me-1 size-12"
                                                ></i>
                                            </u>
                                        </router-link>
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">
                                        Order ID
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail.information_order
                                                .order_id
                                                ? dataDetail.information_order
                                                      .order_id
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">
                                        Jenis Pesanan
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span
                                            class="tag mb-0 pb-0 capitalize blue-cro rounded-pill"
                                        >
                                            {{
                                                dataDetail.order_type === "reg"
                                                    ? "Regular"
                                                    : dataDetail.order_type ===
                                                      "walk_in"
                                                    ? "Walk In"
                                                    : "-"
                                            }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 w-150 fw-medium">
                                        Jenis Layanan
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold capitalize">
                                        {{
                                            dataDetail.information_order
                                                .service_type
                                                ? dataDetail.information_order.service_type
                                                      .split("_")
                                                      .join(" ")
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-12 my-2">
                        <div class="box-manifest h-100">
                            <h5 class="mb-0 fw-bold">Informasi Pembayaran</h5>
                            <table
                                class="w-100 ps-0 fw-bold mt-3"
                                cellpadding="10"
                            >
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">
                                        Jenis Pembayaran
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail.payment
                                                ? "Virtual Account"
                                                : "Tunai"
                                        }}
                                    </td>
                                </tr>
                                <tr
                                    :class="{
                                        'border-bottom': dataDetail?.payment,
                                    }"
                                >
                                    <td class="ps-0 w-150 fw-medium">
                                        Status Pembayaran
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <div class="d-inline-grid">
                                            <router-link
                                                :to="
                                                    '/order/cashier/va/' +
                                                    dataDetail.information_order
                                                        .order_id
                                                "
                                                class="text-link text-center mb-2"
                                                v-if="
                                                    dataDetail?.payment
                                                        ?.status === 'pending'
                                                "
                                            >
                                                Virtual Account
                                            </router-link>
                                            <span
                                                class="badge-unpaid rounded-pill px-4 justify-content-center d-flex"
                                                v-if="
                                                    dataDetail?.payment
                                                        ?.status === 'pending'
                                                "
                                            >
                                                <p class="mb-0">Unpaid</p>
                                            </span>
                                        </div>
                                        <span
                                            class="badge-paid rounded-pill px-4 justify-content-center"
                                            v-if="
                                                dataDetail?.payment?.status ===
                                                    'success' ||
                                                !dataDetail?.payment
                                            "
                                        >
                                            Paid
                                        </span>
                                    </td>
                                </tr>
                                <tr v-if="dataDetail?.payment">
                                    <td class="ps-0 w-150 fw-medium">
                                        Tanggal Pembayaran
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail?.payment?.payment_at
                                                ? dataDetail?.payment
                                                      ?.payment_at
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 my-2">
                <div class="row flex-column">
                    <div class="col-lg-12 my-2">
                        <div class="box-manifest h-100">
                            <h5 class="mb-0 fw-bold">Informasi Penerima</h5>
                            <table
                                class="w-100 ps-0 fw-bold mt-3"
                                cellpadding="10"
                            >
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">
                                        Nama Penerima
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail.information_receiver.name
                                                ? dataDetail
                                                      .information_receiver.name
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">
                                        No. Telp Penerima
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        +{{
                                            dataDetail.information_receiver
                                                .phone
                                                ? dataDetail
                                                      .information_receiver
                                                      .phone
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 w-150 fw-medium">
                                        Alamat Penerima
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            dataDetail.information_receiver
                                                .address
                                                ? dataDetail
                                                      .information_receiver
                                                      .address
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-12 my-2">
                        <div class="box-manifest h-100">
                            <h5 class="mb-0 fw-bold">
                                Rincian Biaya Pengiriman
                            </h5>
                            <table
                                class="w-100 ps-0 fw-bold mt-3"
                                cellpadding="10"
                            >
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">
                                        Biaya Pengiriman
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span class="fw-bold">
                                            {{
                                                currency(
                                                    dataDetail.detail_payment
                                                        .service
                                                )
                                            }}
                                        </span>
                                    </td>
                                </tr>
                                <tr
                                    class="border-bottom"
                                    v-if="
                                        dataDetail.information_order
                                            .service_type ===
                                            'pengiriman_barang' ||
                                        dataDetail.information_order
                                            .service_type === 'pengiriman_motor'
                                    "
                                >
                                    <td class="ps-0 w-150 fw-medium">
                                        Biaya Packing
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span class="fw-bold">
                                            {{
                                                currency(
                                                    dataDetail.detail_payment
                                                        .packing
                                                )
                                            }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 w-150 fw-medium">
                                        Biaya Jasa Tambahan
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span class="fw-bold">
                                            {{
                                                currency(
                                                    dataDetail.detail_payment
                                                        .additional
                                                )
                                            }}
                                        </span>
                                    </td>
                                </tr>
                                <tr
                                    v-for="(additional, index) in dataDetail
                                        .detail_payment.additionals"
                                    :key="index"
                                >
                                    <td class="w-150 fw-medium">
                                        {{ additional.packing }}
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span class="fw-bold">{{
                                            currency(additional.price)
                                        }}</span>
                                    </td>
                                </tr>
                                <tr class="border-bottom border-top">
                                    <td class="ps-0 w-150 fw-medium">Diskon</td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span class="fw-bold text-yellow">
                                            -{{
                                                currency(
                                                    dataDetail.detail_payment
                                                        .discount
                                                )
                                            }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 w-150 fw-medium">
                                        Subtotal
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span class="fw-bold">
                                            {{
                                                currency(
                                                    dataDetail.detail_payment
                                                        .service +
                                                        dataDetail
                                                            .detail_payment
                                                            .packing +
                                                        dataDetail?.detail_payment?.additionals?.reduce(
                                                            (
                                                                total,
                                                                additional
                                                            ) =>
                                                                total +
                                                                additional.price,
                                                            0
                                                        ) -
                                                        dataDetail
                                                            .detail_payment
                                                            .discount
                                                )
                                            }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        class="ps-0 w-150 fw-medium text-yellow"
                                    >
                                        PPH
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span class="fw-bold text-yellow">
                                            -{{
                                                currency(
                                                    dataDetail.detail_payment
                                                        .pph
                                                )
                                            }}
                                        </span>
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 w-150 fw-medium">PPN</td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span class="fw-bold">
                                            {{
                                                currency(
                                                    dataDetail.detail_payment
                                                        .ppn
                                                )
                                            }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 w-150 fw-bold">
                                        Total Pembayaran
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        <span class="fw-bold text-green">
                                            {{
                                                currency(
                                                    dataDetail.detail_payment
                                                        .total
                                                )
                                            }}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="box-manifest mt-4"
            v-if="
                dataDetail.information_order.service_type ===
                'pengiriman_barang'
            "
        >
            <h5 class="fw-bold mb-0">Daftar Barang</h5>
            <div class="table-responsive-custom">
                <table class="table table-bordered mt-3">
                    <thead class="table-light">
                        <tr>
                            <th class="fw-medium">Koli</th>
                            <th class="fw-medium">
                                Deskripsi<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Berat (Kg)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Panjang (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Lebar (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Tinggi (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Berat Volume (Kg)<span class="text-danger"
                                    >*</span
                                >
                            </th>
                            <th class="fw-medium">
                                Kubikasi (m3)<span class="text-danger">*</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody
                        class="padding-body"
                        v-for="(item, index) in dataDetail.items"
                        :key="index"
                    >
                        <tr>
                            <td class="custom">
                                <div class="form-box w-koli">
                                    {{ item.qty }}
                                </div>
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    :value="item.description"
                                    class="form-control"
                                    disabled
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="item.description"
                                />
                            </td>
                            <td class="custom">
                                <div
                                    class="form-box"
                                    v-bind:class="{
                                        green:
                                            item.weight >
                                            Number(item.weight_volume),
                                    }"
                                >
                                    {{ item.weight }}
                                </div>
                            </td>
                            <td class="custom">
                                <div class="form-box">{{ item.length }}</div>
                            </td>
                            <td class="custom">
                                <div class="form-box">{{ item.width }}</div>
                            </td>
                            <td class="custom">
                                <div class="form-box">{{ item.height }}</div>
                            </td>
                            <td class="custom">
                                <div
                                    class="form-box"
                                    v-bind:class="{
                                        green:
                                            Number(item.weight_volume) >
                                            item.weight,
                                    }"
                                >
                                    {{ item.weight_volume }}
                                </div>
                            </td>
                            <td class="custom">
                                <div class="d-flex align-items-center">
                                    <div class="form-box">
                                        {{ item.cubic }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="custom" colspan="8">
                                <div class="w-300">
                                    <label class="fw-medium mb-1">
                                        Perlindungan Barang
                                    </label>
                                    <div
                                        class="form-box gray"
                                        v-if="!item?.packings?.length"
                                    >
                                        Tidak ada perlindungan barang
                                    </div>
                                    <div class="form-box mt-3" v-else>
                                        <div
                                            class="d-flex justify-content-between my-1"
                                            v-for="(
                                                packing, index
                                            ) in item.packings"
                                            :key="index"
                                        >
                                            <p class="mb-0 text-capitalize">
                                                {{
                                                    packing.packing.replace(
                                                        /_/g,
                                                        " "
                                                    )
                                                }}
                                            </p>
                                            <p class="mb-0">
                                                {{ currency(packing.price) }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr class="mt-3" />
            <h5 class="fw-bold mb-0">Hasil</h5>
            <div class="table-responsive-custom">
                <table class="result table table-bordered mt-3">
                    <thead class="table-light">
                        <tr>
                            <th scope="col" class="fw-medium">
                                Berat Aktual (Kg)
                            </th>
                            <th scope="col" class="fw-medium">
                                Berat Volume (Kg)
                            </th>
                            <th scope="col" class="fw-medium">Kubikasi (m3)</th>
                            <th scope="col" class="fw-medium">
                                Charge Weight (Kg)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="form-box">
                                    {{ dataDetail.total_item.actual_weight }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box">
                                    {{
                                        Math.round(
                                            dataDetail.total_item.actual_weight_volume.toFixed(
                                                2
                                            )
                                        )
                                    }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box">
                                    {{
                                        dataDetail.total_item.actual_cubic.toFixed(
                                            2
                                        )
                                    }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box green">
                                    {{
                                        dataDetail.total_item.charge_weight.toFixed(
                                            2
                                        )
                                    }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr class="mt-3" />
            <label class="fw-medium mb-1"> Catatan </label>
            <div class="form-box">
                {{ dataDetail?.note ? dataDetail?.note : "-" }}
            </div>
            <div
                class="d-flex justify-content-end mt-4"
                v-if="dataDetail?.payment?.status !== 'pending'"
            >
                <button
                    @click="
                        downloadInvoice(dataDetail?.information_order?.order_id)
                    "
                    class="btn btn-green d-flex align-items-center px-5 py-2"
                >
                    <img src="../assets/print.svg" alt="print" class="me-2" />
                    Cetak Invoice
                </button>
            </div>
        </div>
        <div
            class="box-manifest mt-3"
            v-if="
                dataDetail.information_order.service_type === 'pengiriman_motor'
            "
        >
            <h5 class="mb-0 fw-bold">
                Detail Pengiriman Motor <span class="text-danger">*</span>
            </h5>
            <div class="row">
                <div class="mt-3 col-lg-6">
                    <label class="mb-2"
                        >Tipe Motor <span class="text-yellow">*</span></label
                    >
                    <div class="form-box">{{ dataDetail.vehicle }}</div>
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="mb-2"
                        >Kapasitas Motor
                        <span class="text-yellow">*</span></label
                    >
                    <div class="form-box">
                        {{
                            dataDetail.cc === "low"
                                ? "100 CC-149 CC"
                                : dataDetail.cc === "middle"
                                ? "150 CC - 249 CC"
                                : "Diatas 250 CC"
                        }}
                    </div>
                </div>
                <div class="col-lg-12 mt-3">
                    <label class="mb-2">Perlindungan Barang </label>
                    <div class="form-box">
                        {{
                            dataDetail.protections
                                .map((item) =>
                                    item.protection
                                        .replace(/_/g, " ")
                                        .replace(/\b\w/g, (char) =>
                                            char.toUpperCase()
                                        )
                                )
                                .join(", ")
                        }}
                    </div>
                </div>
                <div class="col-lg-12 mt-3">
                    <label class="mb-2">Deskripsi</label>
                    <div class="form-box">
                        {{ dataDetail.note ? dataDetail.note : "-" }}
                    </div>
                </div>
                <div
                    class="d-flex justify-content-end mt-4"
                    v-if="dataDetail?.payment?.status !== 'pending'"
                >
                    <button
                        @click="
                            downloadInvoice(
                                dataDetail?.information_order?.order_id
                            )
                        "
                        class="btn btn-green d-flex align-items-center px-5 py-2"
                    >
                        <img
                            src="../assets/print.svg"
                            alt="print"
                            class="me-2"
                        />
                        Cetak Invoice
                    </button>
                </div>
            </div>
        </div>
        <div
            class="box-manifest mt-3"
            v-if="
                dataDetail.information_order.service_type === 'pengiriman_mobil'
            "
        >
            <h5 class="mb-0 fw-bold">
                Detail Pengiriman Mobil <span class="text-danger">*</span>
            </h5>
            <div class="row">
                <div class="mt-3 col-lg-12">
                    <label class="mb-2"
                        >Tipe Mobil <span class="text-yellow">*</span></label
                    >
                    <div class="form-box">
                        {{ dataDetail.vehicle }}
                    </div>
                </div>
                <div class="col-lg-12 mt-3">
                    <label class="mb-2">Deskripsi</label>
                    <div class="form-box">
                        {{ dataDetail.note ? dataDetail.note : "-" }}
                    </div>
                </div>
            </div>
        </div>
        <div
            class="d-flex justify-content-end mt-4"
            v-if="
                dataDetail?.payment?.status !== 'pending' &&
                dataDetail.information_order.service_type ===
                    'sewa_container_20ft' &&
                dataDetail.information_order.service_type ===
                    'sewa_container_20ft'
            "
        >
            <button
                @click="
                    downloadInvoice(dataDetail?.information_order?.order_id)
                "
                class="btn btn-green d-flex align-items-center px-5 py-2"
            >
                <img src="../assets/print.svg" alt="print" class="me-2" />
                Cetak Invoice
            </button>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import { Tooltip } from "bootstrap";

export default {
    components: {},
    name: "OrderProcessDetail",
    data() {
        return {
            dataClaim: {},
            detail_loading: false,
            dataDetail: {
                information_sender: {},
                information_receiver: {},
                information_order: {},
                detail_payment: {},
                items: [],
                total_item: {},
            },
        };
    },
    created() {
        this.getDetail();
    },
    mounted() {
        const dataClaim = sessionStorage.getItem("dataClaim");
        this.dataClaim = JSON.parse(dataClaim);

        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    methods: {
        downloadInvoice(id) {
            this.detail_loading = true;
            var data = {
                receipt_code: id,
            };
            Api.post(`${process.env.VUE_APP_SERVICE_URL}/order/invoice`, data, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.detail_loading = false;
                    const link = res.data.data.link;
                    window.open(link, "_blank");
                })
                .catch((err) => {
                    this.detail_loading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getDetail() {
            this.detail_loading = true;
            const param = this.$route.query;
            var payload = {
                order_id: param.order_id,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/order/detail`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.dataDetail = res.data.data;
                    this.detail_loading = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.detail_loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.badge-unpaid {
    border: 1px solid #ffcf99;
    background-color: #ffe7cc;
    color: #995100;
}
.badge-paid {
    border: 1px solid #b2e6c8;
    background-color: #d9f2e4;
    color: #267347;
}
.w-30 {
    width: 30px;
}
.w-150 {
    width: 150px;
}
.w-120 {
    width: 120px;
}
.table > :not(caption) > * > * {
    border: unset;
}
.table-bordered > :not(caption) > * {
    border: unset;
}
.table > :not(caption) > * > * {
    background-color: unset;
}
tr {
    border-radius: 10px !important;
}
tr td.custom {
    background-color: #ebf6ff !important;
}
.w-handling {
    width: 300px;
}
table tbody tr:first-child td:first-child {
    border-top-left-radius: 6px;
}

table tbody tr:first-child td:last-child {
    border-top-right-radius: 6px;
}

table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
}

table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
}
.padding-body::after {
    height: 10px;
    display: table-row;
    content: "";
}
.result.table > :not(caption) > * > * {
    padding: 0.2rem 0.5rem;
}
.form-control.green {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.form-control.green[disabled] {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.w-desc {
    width: 160px;
}
.w-koli {
    width: 60px;
}
.radius-select {
    border-radius: 10px;
    padding: 8px 12px;
    background: #f3f5f7;
    border: 1px solid #d1d0d4;
}
.w-300 {
    width: 300px;
}
.form-box {
    color: #000;
}
.form-box.gray {
    color: #a3b4c2;
}
</style>
