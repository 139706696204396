<template>
    <div class="padding-container">
        <div class="content-load" v-if="isLoading"></div>
        <div id="loader" v-if="isLoading"></div>
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="goBackListDriver()"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-10 mt-4">
                <div class="box-manifest">
                    <div class="d-flex align-items-center">
                        <h5 class="mb-3 fw-bold">Detail Order</h5>
                        <div class="ms-auto">
                            <span
                                class="tag mb-0 pb-0 px-3 radius"
                                v-bind:class="{
                                    'yellow-cro': isCalculate == false,
                                    'green-cro': isCalculate == true,
                                }"
                            >
                                <template v-if="isCalculate == true">
                                    Sudah Diukur
                                </template>
                                <template v-else> Belum Diukur </template>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <table class="w-100 ps-0 fw-bold" cellpadding="10">
                                <tr class="border-bottom">
                                    <td class="ps-0 w-120 fw-medium">
                                        Nama Pengirim
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{ customerName ? customerName : "-" }}
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 w-120 fw-medium">
                                        Kota Asal
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            originRegency ? originRegency : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 w-120 fw-medium">
                                        CRM Specialist
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{ specialist ? specialist : "-" }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-6">
                            <table class="w-100 ps-0 fw-bold" cellpadding="10">
                                <tr class="border-bottom">
                                    <td class="ps-0 w-120 fw-medium">Muatan</td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{ description ? description : "-" }}
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 w-120 fw-medium">
                                        Kota Tujuan
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            destinationRegency
                                                ? destinationRegency
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 w-120 fw-medium">
                                        Tanggal Deal
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{ dealDate ? dealDate : "-" }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-3" v-if="!isCalculate">
            <Goods @update:data="handleGoodsData" />
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <button
                        class="btn btn-primary px-5 btn-lg"
                        @click="modalSuccessConfirmation = true"
                    >
                        Simpan Data
                    </button>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-3" v-else>
            <h5 class="mb-0 fw-bold">Hasil Ukur - Timbang Barang</h5>
            <div class="table-responsive-custom">
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">Jumlah Koli</th>
                            <th class="text-center" scope="col">Deskripsi</th>
                            <th class="text-center" scope="col">
                                Dimensi Barang (Cm)
                            </th>
                            <th class="text-center" scope="col">Berat (Kg)</th>
                            <th class="text-center" scope="col">
                                Berat Volume (Kg)
                            </th>
                            <th class="text-center" scope="col">
                                Kubikasi (m3)
                            </th>
                            <th class="text-center" scope="col">
                                Perlindungan
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">
                                {{ item.qty }}
                            </td>
                            <td>{{ item.description }}</td>
                            <td class="text-center">
                                {{ item.length }} Cm X {{ item.width }} Cm X
                                {{ item.height }} Cm
                            </td>
                            <td
                                class="text-center"
                                v-bind:class="{
                                    'bc-green':
                                        item.weight >
                                        Number(item.weight_volume),
                                }"
                            >
                                {{ item.weight }} Kg
                            </td>
                            <td
                                class="text-center"
                                v-bind:class="{
                                    'bc-green':
                                        Number(item.weight_volume) >
                                        item.weight,
                                }"
                            >
                                {{ item.weight_volume }} Kg
                            </td>
                            <td class="text-center">{{ item.cubic }} m3</td>
                            <td class="capitalize">
                                <div v-if="!item.packing" class="text-center">
                                    -
                                </div>
                                <div
                                    v-for="(handling, index) in item.packing"
                                    :key="index"
                                >
                                    <span class="tag gray-new radius pb-0">
                                        {{
                                            handling.packing
                                                ? handling.packing.replace(
                                                      /_/g,
                                                      " "
                                                  )
                                                : "-"
                                        }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody class="body">
                        <tr>
                            <td class="p-footer" colspan="4">
                                <div class="pull-right fw-bold">Total</div>
                            </td>
                            <td class="text-center fw-bold p-footer">
                                {{ totalWeight }} Kg
                            </td>
                            <td class="text-center fw-bold p-footer">
                                {{ totalVolume }} Kg
                            </td>
                            <td class="text-center fw-bold p-footer">
                                {{ totalCubic }} m3
                            </td>
                            <td class="p-footer"></td>
                        </tr>
                        <tr>
                            <td class="p-footer" colspan="4">
                                <div class="pull-right fw-bold">
                                    Charge Weight (Kg)
                                </div>
                            </td>
                            <td
                                class="text-center bc-green p-footer"
                                colspan="4"
                            >
                                {{ chargeWeight }} Kg
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <label class="fw-bold mb-1 mt-3">Catatan</label>
            <textarea class="form-control" disabled v-if="!note">-</textarea>
            <textarea
                class="form-control"
                v-model="note"
                disabled
                v-if="note"
            ></textarea>
        </div>
        <div class="modal-vue" v-if="modalSuccessConfirmation">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Data Ukur-Timbang Barang Akan Disimpan
                    </h5>
                    <div class="text-gray-dashboard">
                        Pastikan data orderan dan driver yang dipilih sudah
                        benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalSuccessConfirmation = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="storeData()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="isSave"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="isSave"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Data Ukur-Timbang Barang Berhasil Disimpan
                    </h5>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="goBackListDriver()"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../api/Api";
import Goods from "../components/ServiceType/Goods.vue";

export default {
    components: {
        Goods,
    },
    data() {
        return {
            isLoading: false,
            isCalculate: null,
            customerName: "",
            originRegency: "",
            specialist: "",
            description: "",
            destinationRegency: "",
            dealDate: "",
            goodsData: null,
            items: [],
            isSave: false,
            modalSuccessConfirmation: false,
            modalSuccess: false,
        };
    },
    created() {
        this.getDetail();
    },
    computed: {
        totalWeight() {
            return this.items.reduce(
                (total, item) => total + item.qty * item.weight,
                0
            );
        },
        totalVolume() {
            return Math.round(
                this.items.reduce(
                    (total, item) => total + item.qty * item.weight_volume,
                    0
                )
            );
        },
        totalCubic() {
            return this.items.reduce(
                (total, item) => total + item.qty * item.cubic,
                0
            );
        },
        chargeWeight() {
            return Math.max(this.totalWeight, this.totalVolume);
        },
    },
    methods: {
        goBackListDriver() {
            this.$router.push({
                name: "tally-driver",
                query: {
                    driver_fleet_id: this.$route.query.driver_fleet_id,
                    date_offload: this.$route.query.date_offload,
                },
            });
        },
        handleGoodsData(data) {
            this.goodsData = data;
        },
        getDetail() {
            const param = this.$route.query;
            this.isLoading = true;
            var data = {
                delivery_assignation_id: param.delivery_assignation_id,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/calculate/item/detail`,
                {
                    params: data,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    const data = res?.data?.data;
                    this.isCalculate = data.is_calculate;
                    this.customerName = data.customer_name;
                    this.originRegency = data.origin;
                    this.specialist = data.specialist;
                    this.description = data.description;
                    this.destinationRegency = data.destination;
                    this.dealDate = data.deal_time;
                    this.items = res?.data?.data?.items;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.isLoading = false;
                });
        },
        storeData() {
            this.isSave = true;
            const packingPrice = this.goodsData?.items?.reduce(
                (total, item) => {
                    if (item.protection && item.protection.length > 0) {
                        return (
                            total +
                            item.protection.reduce(
                                (sum, p) => sum + Math.round(p.price),
                                0
                            )
                        );
                    }
                    return total;
                },
                0
            );
            const data = this.goodsData?.items?.map((item) => ({
                qty: item.qty,
                description: item.description,
                weight: item.weight,
                length: item.length,
                width: item.width,
                height: item.height,
                weight_volume: item.volumeWeight,
                cubic: item.cubic,
                protection:
                    item.protection.length > 0
                        ? item.protection.map((h) => ({
                              packing: h.id,
                              price: Math.round(h.price),
                          }))
                        : null,
                delivery_assignation_id:
                    this.$route.query.delivery_assignation_id,
                charge_weight: this.goodsData?.chargeWeight,
                note: this.goodsData?.note,
                packing_price: packingPrice,
            }));

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/calculate/submit`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isSave = false;
                    this.modalSuccess = true;
                    this.modalSuccessConfirmation = false;
                })
                .catch((err) => {
                    this.isSave = false;
                    this.modalSuccessConfirmation = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #c1cdd7;
    background-color: white;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}

.custom-checkbox:checked {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}

.custom-checkbox:checked::after {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #329a5f;
    font-size: 14px;
}
.w-430 {
    width: 430px;
}
textarea {
    resize: none;
}
hr.modal-hr {
    color: #c1cdd7 !important;
    margin: 0;
}
.modal-vue .modal-body-chat.vsm {
    width: 450px;
}
.btn-add {
    color: #40bf76 !important;
    background-color: transparent !important;
    border: 1px solid #40bf76 !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.w-30 {
    width: 30px;
}
.w-120 {
    width: 120px;
}
.not-read-only.table > :not(caption) > * > * {
    border: unset;
}
.not-read-only.table-bordered > :not(caption) > * {
    border: unset;
}
.not-read-only.table > :not(caption) > * > * {
    background-color: unset;
}
tr {
    border-radius: 10px !important;
}
tr td.custom {
    background-color: #ebf6ff !important;
}
.w-handling {
    width: 300px;
}
table tbody tr:first-child td:first-child {
    border-top-left-radius: 6px;
}

table tbody tr:first-child td:last-child {
    border-top-right-radius: 6px;
}

table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
}

table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
}
.padding-body::after {
    height: 10px;
    display: table-row;
    content: "";
}
.result.table > :not(caption) > * > * {
    padding: 0.2rem 0.5rem;
}
.form-control.green {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.form-control.green[disabled] {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.w-desc {
    width: 160px;
}
.w-koli {
    width: 60px;
}
.radius-select {
    border-radius: 10px;
    padding: 8px 12px;
}
.w-300 {
    width: 300px;
}
.scroll-select {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.dropdown-menu {
    padding: 5px 10px 0px 10px;
}
.form-check-input:checked {
    background-color: #329a5f;
    border: #329a5f;
}
div::-webkit-scrollbar {
    display: none;
}
td.bc-green {
    background-color: #d9f2e4;
    color: #329a5f;
    font-weight: 600 !important;
}
</style>
